<template>
  <div class="shop-style">
    <div class="box1">
      <van-search
        background="#fff"
        shape="round"
        v-model="value"
        :placeholder="$t('text.t81')"
        @search="onSearch"
      />
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t('text.t39')"
      :offset="10"
      @load="getlist"
    >
      <div class="box2 start-center">
        <div class="item center-center" v-for="item in list" :key="item.id">
          <div class="itembox" @click="goAhead(`/nftdetail?orderno=${item.orderno}`)">
            <div class="ib-imgbox">
              <img :src="item.info.image" alt="" class="ib-img" />
            </div>
            <div class="ib-info">
              <div class="ib-t1">{{item.title}} #{{item.tokenid}}</div>
              <div class="ib-t2">{{$t('text.t115')}}：</div>
              <div class="ib-t3 start-center">
                <img src="@/assets/img/img17.png" alt="" class="ib-t3-img" />
                <div class="ib-t3-t1">{{item.price}}</div>
              </div>
              <div class="ib-t4 start-center">
                <img src="@/assets/img/img18.png" alt="" class="ib-t4-img" />
                <div class="ib-t4-t1">{{item.info.heat}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      value: "",
      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 20,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onSearch(e) {
      this.loading = false;
      this.finished = false;
      this.page = 1;
      this.list = [];
    },
    getlist() {
      let arg = {
        page: this.page,
        limit: this.limit,
        title: this.value,
      };
      this.$http.get("/order/marketList", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        this.list = [...this.list, ...data];
      });
    },
  },
};
</script>

<style lang="less">
.shop-style {
  .box1 {
    .van-search__content--round {
      border: 1px solid #000;
    }
    .van-search__content {
      background-color: #fff;
    }
  }
  .box2 {
    margin: auto;
    padding: 0 11px;
    flex-wrap: wrap;
    .item {
      width: 176px;
      margin-bottom: 15px;
      .itembox {
        box-shadow: 0 0 6px rgba(50, 50, 50, 0.1);
        width: 150px;
        border-radius: 10px;
        overflow: hidden;
        .ib-imgbox {
          width: 150px;
          height: 159px;
          overflow: hidden;
          .ib-img {
            width: 100%;
            height: auto;
          }
        }
        .ib-info {
          color: #323232;
          font-weight: bold;
          padding: 5px 10px;
          .ib-t1 {
            font-size: 12px;
            margin-bottom: 5px;
          }
          .ib-t2 {
            font-size: 9px;
            margin-bottom: 4px;
          }
          .ib-t3 {
            margin-bottom: 8px;
            .ib-t3-img {
              width: 9px;
              height: 8px;
              margin-right: 5px;
            }
            .ib-t3-t1 {
              font-size: 12px;
            }
          }
          .ib-t4 {
            .ib-t4-img {
              width: 12px;
              height: 10px;
              margin-right: 6px;
            }
            .ib-t4-t1 {
              font-size: 9px;
            }
          }
        }
      }
    }
  }
}
</style>